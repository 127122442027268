export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "manageSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Private Keys"])},
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Private Key"])},
        "existingSshKeyFiles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Existing SSH private key files to ", _interpolate(_named("ownerType")), "/", _interpolate(_named("ownerName"))])},
        "noKeyFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existing SSH private keyfiles"])},
        "copyFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If private key file is on another machine, suggest you use scp or other tool to transfer it to the current client machine, then upload it here. If you copy content into a editor and save it as a file, it may causes format error."])},
        "nameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input private key name"])},
        "duplicatedPrivateKeyFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflict with a existing one"])},
        "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "deleteSshKey": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to delete SSH private key ", _interpolate(_named("name")), ", do you confirm ?"])}
      },
      "zh": {
        "manageSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理私钥"])},
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加私钥"])},
        "existingSshKeyFiles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已有的私钥文件（", _interpolate(_named("ownerType")), "/", _interpolate(_named("ownerName")), "）"])},
        "noKeyFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂时没有可用的私钥文件"])},
        "copyFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果私钥文件在另外一台机器，建议用 scp 或其他工具将文件传送到当前浏览器所在机器再上传，直接拷贝内容再通过编辑器另存为一个文件，可能导致格式错误。"])},
        "nameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入 private key 的名称"])},
        "duplicatedPrivateKeyFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经存在同名的 private key 文件"])},
        "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文件"])},
        "deleteSshKey": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["此操作将要删除私钥 ", _interpolate(_named("name")), "，是否继续？"])}
      }
    }
  })
}
